.layout-footer {
    @include transition(margin-left $transitionDuration);
    background-color: $footerBgColor;
    padding: 1em 2em;

    img {
        vertical-align: middle;
    }

    .footer-text {
        vertical-align: middle;
    }
}