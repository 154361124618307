.small_inputs div {
    line-height: 30px
}

.small_inputs input {
    height: 30px;
}

.ticket_checklist.wizard > div > div > div {
    margin-bottom: 0.5rem;    
}