/* Dashboard */
.dashboard {
    
    .summary {
        position: relative;
        
        .title {
            font-size: 20px;
        }
        
        .detail {
            color: $textSecondaryColor;
            display: block;
            margin-top: 10px;
        }
        
        .count {
            color: #ffffff;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 24px;
            padding: 7px 14px;
            @include border-radius($borderRadius);
            
            &.visitors {
                background-color: #20d077;
            }
            
            &.purchases {
                background-color: #f9c851;
            }
            
            &.revenue {
                background-color: #007be5;
            }
        }
    }

    .highlight-box {
        height: 100px;
        @include clearfix();
        
        .initials {
            height: 100%;
            float: left;
            width: 50%;
            text-align: center;
            padding: 1em;

            > span {
                font-size: 48px;
            }
        }
        
        .highlight-details {
            height: 100%;
            background-color: #ffffff;
            float: left;
            width: 50%;
            padding: 1em;

            i {
                font-size: 24px;
                vertical-align: middle;
                margin-right: .25em;
            }
            
            .count {
                color: $textSecondaryColor;
                font-size: 36px;
                display: block;
            }
        }
    }
    
    .task-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        
        li {
            padding: .5em .25em;
            border-bottom: 1px solid $dividerColor;
            @include clearfix();
        }
        
        .p-checkbox {
            vertical-align: middle;
            margin-right: .5em;
        }
        
        .task-name {
            vertical-align: middle;
        }
        
        i {
            float: right;
            font-size: 24px;
            color: $textSecondaryColor;
        }

        .p-panel-content {
            min-height: 256px;
        }
    }

    .contact-form {
        .p-panel-content {
            min-height: 256px;
        }
    }

    .contacts {

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                border-bottom: 1px solid $dividerColor;
                
                button {
                    padding: 9px;
                    width: 100%;
                    box-sizing: border-box;
                    text-decoration: none;
                    position: relative;
                    display: block;
                    @include border-radius(2px);
                    @include transition(background-color .2s);
                    
                    .name {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        font-size: 18px;
                    }
                    
                    .email {
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        font-size: 14px;
                        color: $textSecondaryColor;
                    }
                    
                    &:hover {
                        cursor: pointer;
                        background-color: #eeeeee;
                    }
                }
                
                &:last-child {
                    border: 0;
                }
            }
        }
 
        .p-panel-content {
            min-height: 256px;
        }
    }
        
    .activity-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        
        li {
            border-bottom: 1px solid $dividerColor;
            padding: 16px 8px;
            
            .count {
                font-size: 24px;
                color: #ffffff;
                background-color: #007be5;
                font-weight: 700;
                display: inline-block;
                padding: .25em .5em;
                @include border-radius($borderRadius);
            }
            
            &:first-child {
                border-top: 1px solid $dividerColor;
            }
            
            &:last-child {
                border: 0;
            }

            .p-g-6:first-child {
                font-size: 18px;
                padding-left: 0;
            }
            
            .p-g-6:last-child {
                text-align: right;
                color: $textSecondaryColor;
            }
        }
    }
}
