.dialog_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_row {
  max-height: 50;
}

.error_message {
  width: 33px;
  height: 33px;
  margin-left: 3px;
}

textarea:focus ~ label,
textarea.ui-state-filled ~ label,
textarea:not(:empty) ~ label {
  top: -0.75em;
  font-size: 12px;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  left: 10px;
  width: 100%;
  top: -0.9em;
}

.filter_row {
  display: flex;
  justify-content: space-between;

  .filter_row_spacer {
    width: 25px;
  }

  .p-inputgroup {
    margin-top: 20px;
  }

  .p-float-label.input_wrapper {
    margin-bottom: 0px;

    input {
      width: 100% !important;
    }
  }
}

.customer_filter_button_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

#cb_fuzzy + label {
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    color: #007ad9 !important;
  }
}

.top_bar_title {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 24px;
}

.top_bar_title_responsive {
  width: auto !important;
}

.responsive_address_item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.add_payment_row > span:first-child {
  width: 100%;
  margin-top: 25px;

  :first-child {
    width: 100%;
  }
}

.sortable_header {
  cursor: pointer;  
}

.sortable_header:hover {
  color: #2196F3;
}
