.wizard_dialog {
    width: 95vw;
}

@media only screen and (min-width: 1439px) {
    .wizard_dialog {
        width: 65vw;
    }
}

@media only screen and (min-width: 2100px) {
    .wizard_dialog {
        width: 45vw;
    }
}
.manually_created_event{
    border: 3px solid red !important;
}

.history_dialog{
    width: 75% !important;
}