#peppol_export_addresses {
  width: 420px;
  max-width: 420px !important;
}

.peppol-status {
  div {
    margin: 0;
    padding: 0;
  }

  .peppol-status-img {
    display: flex;
  }
  .peppol-status-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    i {
      font-size: 20px;
    }

    i.pi-exclamation-triangle {
      color: orange;
    }
  }

  .peppol-icon {
    width: 50px;
    height: auto;
  }

  .pi-times {
    color: red;
  }

  .pi-check {
    color: rgb(33, 127, 33);
  }
}

.bill_filter_peppol {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 0;

  .peppol-icon {
    width: 35px;
    height: auto;
  }
}

.filter_full_width {
  padding-bottom: 15px;
}

#bill_filter_table td {
  padding: 0px !important;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  left: 10px;
  width: 100%;
  top: -0.9em;
}

.bill_filter_row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  .p-dropdown {
    width: 100% !important;
  }

  .p-float-label.input_wrapper {
    margin-bottom: 0px;

    input {
      width: 100% !important;
    }
  }
}

.bill_filter_row.filter_full_width > * {
  width: 100% !important;
}

.bill_selector_base {
  display: flex;
}

.bill_edit_row {
  display: flex;
  align-items: flex-end;

  .p-dropdown {
    width: 100%;
    max-width: 100%;
  }

  .p-inputnumber {
    width: 100%;
  }

  .comment_text_area {
    top: 20px;
    width: 100%;
  }
}

.bill_edit_row_resp {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .p-float-label,
  .p-float-label > input,
  .p-dropdown,
  .p-inputnumber,
  .p-togglebutton,
  .p-inputtextarea {
    width: 100%;
  }

  .p-togglebutton {
    margin-bottom: 15px;
  }
}

.p-panel {
  margin-bottom: 10px;
}

.bill_position_panel_responsive > .p-toggleable-content > .p-panel-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.status_select_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multi_dialog_button_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.files_drag_active {
  color: #2196f3 !important;
  border: 1px solid #2196f3 !important;
}

#bill_filter_table
  > .p-datatable-wrapper
  > table
  > thead
  > tr
  > th
  > .p-column-header-content
  > .p-column-title {
  width: 100%;
}
