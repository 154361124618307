.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  left: 10px;
  width: 100%;
  top: -0.9em;
}

.payment_filter_row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  > .p-float-label {
    width: 100% !important;
    margin-top: 20px;
    margin-bottom: 0px !important;
    > span {
      margin-bottom: 0px;
      width: 100% !important;

      input {
        width: 100% !important;
      }
    }
  }
}

.payment_selector_base {
  display: flex;
}
