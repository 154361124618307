

.driver_status_container{
    padding: 0;
    margin-top: 1%;
    display:flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 100%;
    overflow-x: auto;
    width: 100%;
}



@media screen and (max-width: 1600px) {
    .driver_status_header{
        height: 7.5vh;
    }
    
  } 