.footer-sidebar {
  width: calc(100% - 250px) !important;
  max-height: 100px;
}

.p-sidebar-mask:has(div) {
  .p-sidebar-mask {
    justify-content: end !important;
  }
}

body:has(.layout-static-sidebar-inactive) {
  .footer-sidebar {
    width: 100% !important;
  }
}

body:has(.layout-mobile-sidebar-active) {
  .footer-sidebar {
    width: calc(100% - 250px) !important;
  }
}
