.peppol-exporter-container {
  height: 100%;
  background-color: #0069b5 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  .loading {
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .peppol-icon {
      height: 25px;
      width: auto;
    }

    .loading-icon {
      font-size: 25px;
    }
  }

  .processing {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;

    .status {
      color: white;
      font-size: 20px;
    }
    .progress-bar {
      width: 225px;
    }
  }

  .finished {
    .close-button-container {
      position: absolute;
      top: 15%;
      right: 10px;
    }

    .summary-container {
      color: white;
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 20pt;
        gap: 5px;

        .checked-icon {
          color: greenyellow;
        }
        .failed-icon {
          color: #ff8c8c;
        }
        i {
          font-size: 18pt;
        }
      }
    }
  }
}
