.user_edit_root {
  display: flex;
}

.p-dialog-title {
  padding-left: 10px;
}

.dialog-emergency-contact {
  .p-dialog-content {
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 30px;
  }
}