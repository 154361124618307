.split_label {
  font-size: 12px;
}
.input_wrapper {
  margin-top: 20px;  
  width: 100%;
}

.invalid_input > input {
  border: 1px solid red;
}

.invalid_input label {
  color: red;
}

.account_button_row {
  display: flex;
  justify-content: space-between;
}

.fuzzy_toggle-button {
  min-width: 95px;
}

.fuzzy_toggle-button_responsive {
  min-width: 32px;
}

.scheduler_appointment_customer_overlay {
  position: absolute !important;
  top: unset !important;
}

.user_edit_responsive {

  .locale_select {
    margin-top: 25px;
    width: 100%;
  }
}
// Hide upload file size.
.p-fileupload-row > div:nth-child(2) {
  display: none;
}
// Hide file archive editor upload button.
.p-fileupload-buttonbar > button:nth-child(2) {
  display: none;
}