.driver_status_header {
  /*   background-color: lightgray; */
  height: 7.5vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2.5px 5px rgba(0, 0, 0, 0.2); /* Adding a modern shadow */
  margin-bottom: 2.5%;
}
.driver_status_element {
  width: 260px;
  height: 180px;
  padding-left: 15px;
  padding-right: 10px;

  .element-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 180px;
  }

  .driver_name {
    font-size: 10pt;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .status {
    font-size: 8pt;
    font-weight: bold;
  }

  .licence_plate {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    font-size: 8.5pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.driver_status_header_toolbar {
  padding-left: 1%;
  padding-right: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
.left_right_flex {
  display: flex;
  flex-direction: row;
}

.histcount {
  align-self: center;
  margin-left: auto;
  margin-right: 10px;
  font-size: 7pt;
  font-weight: bold;
}
.bold-text {
  p {
    font-weight: bold;
    font-size: 7pt;
  }
}

.tooltip-line {
  i {
    margin-right: 10px;
    font-weight: bolder;
  }
  line-height: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
