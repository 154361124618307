.fc-list-event-time, .fc-list-event-graphic {
    display: none;
}

.fc-day-sat, .fc-day-sun {
    background: #faffd8;
}

.vacation_name {
    white-space: break-spaces;
}

#hours-input, #minutes-input {
    &> .p-inputnumber-input {
        max-width: 60px;
    }
}

#vacation-new-popup_content.p-dialog-content, #vacation-edit-popup_content.p-dialog-content  
{         
    overflow-y: hidden;
}