.in_checklist > div > .p-inputgroup {
    height: 30px;
}

#dlg_app_create_content .p-fieldset-legend.p-unselectable-text {
    padding-left: 1rem !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#dlg_app_create_content .p-fieldset-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#scheduler_calendar_placeholder.toolbar_small+.p-toolbar, #scheduler_calendar_placeholder.toolbar_big+.p-toolbar {
    width: calc(100% - 282px);
}

#scheduler_calendar_placeholder+.p-toolbar {
    position: fixed;
    top: 50px;
    width: 83vw;
    z-index: 999;
}

#scheduler_calendar_placeholder {
    height: 72px;
}


.multi_select_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tbody > tr > td, .multi_select_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead > tr > th {
    padding: 3px;
}
.multi_button_column > .p-column-header-content, .multi_button_column > .p-column-header-content > .p-column-title, #multi_select_calendar, 
.multi_select_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tbody > tr > td >.p-float-label,
.multi_select_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead > tr > th,
.multi_select_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead > tr > th > .p-column-header-content,
.multi_select_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead > tr > th > .p-column-header-content > .p-column-title {
    height: 100%;
    width: 100% !important;    
}
.p-datepicker-calendar > tbody > tr > td > span.p-highlight
{
    color: #ffffff !important;
    background: #2196F3 !important;
}

.MuiTableRow-root:nth-child(4) {
    display: none !important;
}
.horizontal_div {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.schedule_div_large {
    flex-grow: 3;
}

.schedule_div_small {
    flex-grow: 1;
}

.makeStyles-autoWidth-8 > div:nth-child(1) {
    display: none;
}

.status_spacer {
    width: 5px;
    margin-right: 5px;
}

.status_ambulance {
    background-color: lightcoral;
}

.status_caddy {
    background-color: orange;
}

.status_taxi {
    background-color: lime;
}

.avatar_small {
    height: 60px;
}

.cancelled {
    text-decoration: line-through;
}

.schedule_popup {
    padding: 5px;
}
.fc-timegrid-slots > table  tbody  tr, .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
    height: 46px !important;
}

.fc .fc-bg-event {
    opacity: 1 !important;
}

.fc-timegrid-col {
    border: 1px solid #bfbfbf !important;
}

[data-resource-id], .fc-timegrid-bg-harness {
    border-bottom: 1px solid #999999 !important;
}

td.fc-timegrid-col:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    border-left: 1px solid #999999 !important;
}

td.fc-timegrid-col:nth-child(15) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    border-right: 1px solid #999999 !important;
}

.fc-col-header > tbody:nth-child(2) > tr:nth-child(1) > th:nth-child(2){
    background-color: #404040; color: white; padding: 6px;
}

.fc-col-header > tbody:nth-child(2) > tr:nth-child(1) > th:nth-child(3){
    background-color: #4040408f; color: white; padding: 6px;
}

.fc-col-header > tbody:nth-child(2) > tr:nth-child(1) > th:nth-child(4){
    background-color: rgb(185, 185, 185);
    color: rgb(20, 20, 20); padding: 6px;
}

.fc-col-header > tbody{
    border-bottom: 4px solid white;
}

.fc-col-header-cell[data-resource-id*="-1-"]{
    /*background-color: #664e2e; Jeff did not like the colors :-(
    color: #fffbe7;
    padding: 3px!important;*/
    background-color: #ffffff;
    color: #333333;
    padding: 3px!important;
}

.fc-col-header-cell[data-resource-id*="-2-"]{
    /*background-color: #b11919;
    color: #faebeb;
    padding: 3px!important;*/
    background-color: #ffffff;
    color: #333333;
    padding: 3px!important;
}

.fc-col-header-cell[data-resource-id*="-3-"]{
    /*background-color: #2e9bc9;
    color: #ebeef9;
    padding: 3px!important;*/
    background-color: #ffffff;
    color: #333333;
    padding: 3px!important;
}

.fc-col-header-cell[data-resource-id="3"]{
    background-color: #ffffff;
    color: #333333;
    padding: 3px!important;
}

body {
    background-color: white!important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    height: 1.75rem !important;
    width: 1.75rem !important;
}

div.p-dialog-mask .p-dialog-header{
    background-color: #0069B5;
    color: white;
    height: 40px;
    padding: 6px 10px 3px 0px;
}

div.p-dialog-mask button.p-dialog-header-icon {
    color: white;
}

div.p-dialog-mask button.p-dialog-header-icon span {
    color: white;
}

div.p-dialog-content [role="tablist"]{
    padding: 10px 0 10px 0;
}

.fc-scroller-harness {
    top: 0px;
}

.fc-timegrid-slot.fc-timegrid-slot-lane, .fc-timegrid-col {
    border: 1px solid rgb(116, 116, 116) !important;
}

.fc-list-event {
    cursor: pointer;
}

.checkbox_input, .checkbox_input>input {
    height: 23px;
}

.fc-event-main > div {
    height: 100%;
}

.fc-timegrid-col-events {
    margin: unset !important;
}

.p-cascadeselect-item-active>.p-cascadeselect-sublist {
    overflow-y: scroll;
    max-height: 175px;
}

.second_violin > div {
    max-height: 12px;
}

svg.shadow_icon {
    filter: drop-shadow(1px 1px black);
}

strong.shadow_icon {
    text-shadow: 1px 1px black;
}

.tooltip_icon_container {
    width: 18px;
    display: flex;
    justify-content: center;
}

.customized-timeline{
    margin-top: 2%;
}

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.generated-history-content{
    line-height: 2;

    .red{
        color: red !important;
    }

    .green{
        color: green !important;
    }
}