.table_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    .error {
        align-items: center;
        flex-direction: row;
        cursor: unset;
    }
}

.combobox-wrapper {
    margin-top: 20px;    
}

.tool_row {
    > .p-panel {
        width: 100%;
        margin-left: 5px;
    }

  > .p-button {
    height: min-content;    
  }
}