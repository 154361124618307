/* Place your extensions here to make migration to a new release easier. */
.layout-logo {
    display: flex;
    margin: 15px;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 15px;
    }

    .dark_logo {
        color: white;
    }

    .light_logo {
        color: black;
    }
}

.active-route {
    font-weight: bold;
    background: gray;
}

.p-datatable-header {
    display: flex;
    justify-content: flex-end;
}

.p-dropdown-item:hover, .p-listbox-item:hover, .p-multiselect-item:hover {
    color: #000000 !important;
    background: #adadad !important;
}

.p-dropdown-item.p-highlight {
    background: #A5D9FF !important;
}

.inline_edit_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tbody > tr > td  {
    .p-link {
        color: white;
    }
    .p-row-editor-init {
        background-color: #2196F3;
        :hover {
            background-color: #0d89ec;
        }
    }

    .p-row-editor-save {
        background-color: #22C55E;
        :hover {
            background-color: #16A34A;
        }
    }

    .p-row-editor-cancel {
        background-color: #F59E0B;
        :hover {
            background-color: #D97706;
        }
    }

    input {
        width: 100%;
        max-width: 175px;
    }
}

.inline_edit_table > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead > tr > .p-filter-column > .p-column-filter > .p-column-filter-element {
    width: 100% !important;

    input {
        width: 100% !important;
    }
}

.custom_delete_button {
    height: 2rem !important;
    width: 2rem !important;
}

/* ---------- FIX for IOS not scrolling ----------------- */
.p-dialog-content {
/*     overflow: scroll !important; */
/*     max-height: none !important; */
    overflow-y: scroll;
  }

.p-dialog{
/*     overflow: scroll !important ; */
    -webkit-overflow-scroll: touch !important;
    overflow-y: hidden;
/*     max-height: none !important; */
}
  /* ---------------------------------------------------- */