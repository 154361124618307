.canvas_box {
    border: 2px solid black;
    height: 140px;
    width: 560px;
}

.ticket_vertical div {
    line-height: 30px;
}

.ticket_vertical input, .ticket_vertical .checkbox_input {
    height: 30px;
}

.ticket_checklist > div > div > div {
    margin-bottom: 20px;
}

.ticket_checklist > div > div {
    margin-right: 0.5rem;
}

.ticket_checklist .p-float-label {
    margin-top: 0px;
}

.ticket_checklist .p-checkbox-label {
    line-height: initial;
}

.ticket_checklist .p-checkbox, .ticket_checklist .p-checkbox-box, .ticket_vertical .p-radiobutton, .ticket_vertical .p-radiobutton-box {
    height: 30px !important;
    width: 30px !important;
}

.ticket_radio {
    margin: 0.5rem;

}

.ticket_vertical .p-inputtext {
    width: 100%;
}

.ticket_column_title {
    line-height: initial !important;
    font-weight: bold;
    margin-top: 1rem;
}

.highlighted-label{
    color: red !important;
    font-size: 17px !important;
}


.manual-dialog{
    width: 30vw !important;
}

.responsive-input-drives{
    width: 100%;
}

@media screen and (max-width: 1600px) {
    .responsive-input-drives{
        max-width: 150px !important;
    }

    .responsive-km-input-drives{
        max-width: 103px !important;
    }

    .manual-dialog{
        width: 50vw !important;
    }

    .checkbox_input{
        input {
            width: 100% !important;
        }
    }
}
