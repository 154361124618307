.account_button_row
{
    display: flex;
    justify-content: space-between;
}

.tool_row {
    > .p-panel {
        width: 100%;
        margin-left: 5px;
    }

  > .p-button {
    height: min-content;    
  }
}