.time_input, .time_input > .p-inputnumber-input {
    width: 90px;

    &.p-calendar {
        width: 120px;
    }
}

.driver_header_base {
    background-color: lightgray;
    &.blocked {
        background-color: #ffb1a8;
    }

    .input_container {
        width: 100%;

        .p-checkbox, .p-checkbox-box {
            height: 30px !important;
            width: 30px !important;
        }

        &> div {
            display: flex;
            justify-content: flex-start;
            gap: 40px;
            align-items: center;   
            margin-bottom: 0.5rem;  
        }                

        &>div:last-child {
            align-items: flex-end;
        }
    }

    .driver_header_label_col, .label-input {
        font-weight: bold;
    }
    .remark-input  {
        flex-grow: 1;

        &> textarea {
            width: 100%;
        }

        &> div {
            width:100%;
            border: 1px solid gray;
            padding: 10px;
            background-color: #ffffff96
        }
    }
}

.drives_table > .p-datatable-wrapper > table > thead > tr {
    > th {
        background-color: #0069b5;
        color: white;
    }

    > .drive_no_header {
        background-color: white;
    }
}

.drive_icon {
    max-width: 30px;

}

.drive_no_background {
    background-color: white !important;
}

.drive_row_return {
    background-color: black !important;
    border: 3px solid red;
}

.drive_row_return > td {
    border: none !important;
    color: white;
}

.daily_group_row {
    background-color: #94c7ec !important;
    color: black !important;
}

.custom-dialog{
    padding: 5px;
    margin-top: 20px;

    div {
        padding-top: 10px;
    }

    span{
        width: 100%;
        display: block;
        margin: auto;
        .p-component{
            width: 100%;
        }
    }
}
.p-dialog-footer{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* Dynamic Column in DailyReportTable to define if a row is a manual entry*/

.daily_cancelled_row{
    background-color: lightgray !important;

    td > div{
        text-decoration: line-through !important;
    }
}
.force-grow{
    flex-grow: 1 !important;
}

.force-shrink{
    display: none !important;
}


.p-confirm-dialog{
    .p-dialog-content{
        padding: 20px !important;
        overflow: hidden !important;
    }
    .p-dialog-footer{
        padding-bottom: 20px !important;
    }
}

.custom-button{
    width: 60px;
    span{
        margin-left: -4px !important;
    }
}

.custom-button-right{
    margin-left: 10px;
}

.drive_header_driver_status{
    justify-content: space-around;
    align-items: center;
    div > .p-radiobutton{
        margin-left: 30px;
    }

    div > label{
        margin-left: 10px;
        font-size: 13pt;
    }
}

#header-status-radiobutton{
    display: flex;
    align-items: center;
}

.calendar-button-container{
    display: flex;
}
@media screen and (max-width: 1400px) {

    .drive_header_driver_status{
        div > .p-radiobutton{
            margin-left: 15px;
        }
    
        div > label{
            margin-left: 8px;
            font-size: 12pt;
        }
    }
    

    .report-buttons-container{
        gap: 2.5%;
        button > span{
            font-size: 12px;
        }
    }

    .dropdown-cars{
        width: 130px;
    }
}

.driver_name_day{
    .col-4, .col-3, .col-6, .col-9{
        display: flex;
        gap: 10px;
        font-weight: bold;
    }
}

.button-cell{
    display: flex !important;
}

@media screen and (max-width: 1600px) {

    tr{
       td:last-of-type {
            padding: 0 !important;
            align-items: center;
            justify-content: end;
            margin-right: 2%;
        }
    }

    .custom-button-right{
        margin-left: 8px;
    }

    .p-inputnumber-input{
        width: 50px !important;
    }

    .label-input{
        width: 200px !important;
    }

    .drives_table > .p-datatable-wrapper > table > thead > tr {

        > .drive_no_header {
            justify-content: center;
        }
    }
    
    .p-datatable-emptymessage{
        td{
            justify-content: start !important;
            height: 50px;
        }
    }
  } 
