.address_button_row {
  display: flex;
  justify-content: space-between;
}

.filter_row_spacer {
  width: 25px;
}

.column-customer-name {
  display: flex;
  align-items: center;
  gap: 15px;
}
