.tool_row {
  > .p-panel {
    width: 100%;
    margin-left: 5px;
  }

  > #bills_tool_button {
    height: min-content;
  }
}

#bills_tool_button .p-menu {
  width: 400px !important;
}