.link_body {
    display: block;
    width: 100%;
    padding: 1em !important;        
    transition: color 0.2s;    
}

.offline_message_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-topbar {
    background: #0069b5 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .7em 1.5em .7em 1.5em !important;

    >div {
        width: 33%;
    }
}

.layout-topbar-icons {    
    align-items: center;
    display: flex !important;
    justify-content: flex-end;
}

.offline_button {
    border: 1px white solid;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    padding: 3px;
}

.offline_button:hover {
    background-color: #0069b5 !important;
}